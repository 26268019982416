<template>
    <div>
        <!-- Breadcrumbs -->
        <v-container v-if="smAndUp">
            <v-row>
                <v-col>
                    <v-breadcrumbs
                        :items="breadCrumbs"
                        divider=">"
                    ></v-breadcrumbs>
                </v-col>
            </v-row>
        </v-container>

        <!-- ジム風景 -->
        <v-container>
            <v-row justify="center">
                <v-col cols="auto" align="center">
                    <h1><heading>ジム風景</heading></h1>
                </v-col>
            </v-row>
            <v-row class="mb-4">
                <v-col
                    cols="4"
                    v-for="item in imageScenes"
                    :key="item[0]"
                    class="pa-1"
                >
                    <v-img
                        :src="item[0]"
                        @click.stop="clickImage(item[1])"
                    ></v-img>
                </v-col>
            </v-row>
            <v-row>
                <v-card flat class="mx-2 mt-n2 mb-8">
                    <heading
                        ><span class="red--text">施設</span>の雰囲気</heading
                    >
                    <v-col
                        align="left"
                        :class="smAndUp ? 'text-h6' : 'text-body-1'"
                        class="px-0 font-weight-light"
                    >
                        美容室のようなオシャレでナチュラルなイメージ。
                        男性にも女性にも、運動初心者にとっても万人がレッスンを受けやすいようなゆったりとした個別空間。
                    </v-col>
                </v-card>
            </v-row>
            <v-row class="mb-4">
                <v-col
                    cols="4"
                    v-for="item in imageFacility"
                    :key="item[0]"
                    class="pa-1"
                >
                    <v-img
                        :src="item[0]"
                        @click.stop="clickImage(item[1])"
                    ></v-img>
                </v-col>
            </v-row>
            <v-row>
                <v-card flat class="mx-2 mt-n2 mb-8">
                    <heading class="mb-0"
                        ><span class="red--text">マシン</span>のこだわり
                    </heading>
                    <v-col
                        align="left"
                        :class="smAndUp ? 'text-h6' : 'text-body-1'"
                        class="px-0 font-weight-light"
                    >
                        筋トレ 中級者 / 上級者
                        にも対応した業界最高基準の高品質なフリーウェイトマシン。
                        もちろん初心者の方がいきなりやるわけではありません。
                    </v-col>
                </v-card>
            </v-row>
            <v-row class="mb-4">
                <v-col
                    cols="4"
                    v-for="item in imageMachine"
                    :key="item[0]"
                    class="pa-1"
                >
                    <v-img
                        :src="item[0]"
                        @click.stop="clickImage(item[1])"
                    ></v-img>
                </v-col>
            </v-row>
            <v-row>
                <v-card flat class="mx-2 mt-n2">
                    <heading class="mb-0"
                        ><span class="red--text">レンタル</span>シューズ
                    </heading>
                    <v-col
                        align="left"
                        :class="smAndUp ? 'text-h6' : 'text-body-1'"
                        class="px-0 font-weight-light"
                    >
                        23.5cm～25.5cm 26.0cm～28.0cm
                        まで1cm刻みで無料のレンタルシューズをご用意しています。
                        マイシューズをお忘れの方、会社帰りで持ち運ぶのが面倒な方はご自由にお使いいただけます。
                    </v-col>
                </v-card>
            </v-row>
            <v-row justify="center">
                <v-col md="8">
                    <link-tag compName="OurTeam">トレーナー</link-tag>
                </v-col>
            </v-row>
        </v-container>
        <v-dialog v-model="dialog" :width="smAndUp ? '61%' : null">
            <v-card>
                <v-img
                    :src="imagePath"
                    :height="smAndUp ? '556' : null"
                    contain
                ></v-img>
            </v-card>
        </v-dialog>

        <footer-contents></footer-contents>
    </div>
</template>

<script>
import _ from 'lodash'
import Heading from '@/components/Heading.vue'
import FooterContents from '@/components/FooterContents.vue'
import LinkTag from '@/components/LinkTag.vue'
export default {
    mounted() {
        document.title = `${this.$route.meta.title} | パーソナルトレーニングジム【REVOISTレボイスト】`
    },
    components: {
        Heading,
        FooterContents,
        LinkTag,
    },
    data() {
        return {
            dialog: false,
            imagePath: null,
        }
    },
    methods: {
        clickImage(src) {
            this.imagePath = src
            this.dialog = true
        },
    },
    computed: {
        smAndUp() {
            return this.$vuetify.breakpoint.smAndUp
        },
        breadCrumbs() {
            return [
                {
                    text: 'トップ',
                    disabled: false,
                    href: './',
                },
                {
                    text: 'ジム風景',
                    disabled: true,
                    href: 'personal-training',
                },
            ]
        },
        imageScenes() {
            const imageNames = ['11', '12', '13', '14', '15', '16']
            return _.map(imageNames, (item) => {
                return [
                    '/static/pc_scene/' + item + '.png',
                    'static/scene_high_reso/' + item + '.JPG',
                ]
            })
        },
        imageFacility() {
            const imageNames = ['21', '22', '23', '24', '25', '26']
            return _.map(imageNames, (item) => {
                return [
                    '/static/pc_scene/' + item + '.png',
                    'static/scene_high_reso/' + item + '.JPG',
                ]
            })
        },
        imageMachine() {
            const imageNames = ['31', '32', '33', '34', '35', '36']
            return _.map(imageNames, (item) => {
                return [
                    '/static/pc_scene/' + item + '.png',
                    'static/scene_high_reso/' + item + '.JPG',
                ]
            })
        },
    },
}
</script>

<style lang="scss" scoped></style>
